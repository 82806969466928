.header {
  background: rgb(195, 102, 0);
  background: linear-gradient(
    180deg,
    rgba(195, 102, 0, 1) 0%,
    rgba(195, 102, 0, 1) 10%,
    rgba(180, 96, 0, 1) 10%,
    rgba(180, 96, 0, 1) 20%,
    rgba(165, 85, 7, 1) 20%,
    rgba(165, 85, 7, 1) 30%,
    rgba(148, 78, 18, 1) 30%,
    rgba(148, 78, 18, 1) 45%,
    rgba(135, 70, 29, 1) 45%,
    rgba(135, 70, 29, 1) 65%,
    rgba(113, 57, 39, 1) 65%,
    rgba(113, 57, 39, 1) 75%,
    rgba(113, 61, 42, 1) 75%,
    rgba(113, 61, 42, 1) 85%,
    rgba(81, 42, 64, 1) 85%,
    rgba(81, 42, 64, 1) 95%
  );
}
