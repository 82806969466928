@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'VCR_OSD_MONO';
  src: local('VCR_OSD_MONO'),
    url(./fonts/VCR_OSD_MONO.001.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'VCR_OSD_MONO',  -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(#1e0b61, #c76a04);
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}


/* https://github.com/tailwindlabs/tailwindcss/issues/162 */
@layer utilities {
  @variants responsive {
    .text-shadow-black {
      text-shadow: 1px 1px 0px black;
    }
  }
}
